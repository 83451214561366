/* Global */
.App {
    /*min-height : 100vh;*/
}

/*  Home page */
#home {
    background : url('images/bg.gif') no-repeat top left;
    min-height : 100vh;
}

#home .logo {
    display: block;
    position: fixed;
    top: 5%;
    left: 15%;
}

#home .infos_button {
    position: fixed;
    top: 5%;
    right: 15%
}

#home .bloc_texte {
    color: #FFF;
    text-align : center;
    width: 30%;
    position: fixed;
    top: 20%;
    right: 15%;
}

#home .bloc_bouton {
    color: #FFF;
    position: fixed;
    top: 25%;
    left: 15%;
}

#home .texte_1 {
    margin-bottom: 40px;
    font-size: 30px;
}
#home .texte_1 span.texte-red {
    color: #f71a1e;
}
#home .texte_2 {
    font-size: 40px;
    font-weight: bold;
}

#home .bouton_home {
    display: inline-block;
    width: 404px;
    height: 169px;
    cursor: pointer;
}

#home .bouton_observation_physique {
    background-image : url('images/bouton_observation_physique.png');
    background-size: 100% 100%;
}

#home .bouton_observation_internet {
    background-image : url('images/bouton_observation_internet.png');
    background-size: 100% 100%;
}

#home .texte_3 {
    position: fixed;
    bottom: 5%;
    left: 15%;
    font-size: 20px;
    color: #00206d;  
}


/* Template */
#page {
    background : #BBC9EA;
    min-height : 100vh;
}

#page_inner {
    max-width: 1080px;
    margin: 0 auto;
    padding: 20px 0;
}

#header {
    padding-bottom: 10px;
    position: relative;
} 
#header .logo img {
    height: 80px;
} 
#header .mention {
    position: absolute;
    bottom: 0;
    right : 0;
    color: #FFF;
    font-size: 24px;
    font-weight: bold;
}

#content {
    background: #FFF;
    padding: 20px;
}


/* Form */
.form-section {
    color: #00206d;
    padding-bottom : 50px;
}
.form-section-title {
    margin-bottom: 30px; 
    height: 48px;
    padding-top: 12px;
    padding-left: 60px; 
    text-transform: uppercase;
    color: #00206d;
}
.form-section-title span {
    display: inline-block;
    padding-bottom: 5px;
    background: url('images/barre_titre.png') no-repeat bottom left;
}

.icon_location {
    background: url('images/icons/location.png') no-repeat;
}

.icon_identification {
    background: url('images/icons/identification.png') no-repeat;
}

.icon_marchandises {
    background: url('images/icons/marchandises.png') no-repeat;
}

.icon_truck {
    background: url('images/icons/truck4.png') no-repeat;
}

.icon_city {
    background: url('images/icons/city.png') no-repeat;
}

.icon_internet {
    background: url('images/icons/internet.png') no-repeat;
}

.icon_observations {
    background: url('images/icons/observations3.png') no-repeat;
}

.icon_photos {
    background: url('images/icons/photos.png') no-repeat;
}

.add_photos_button {
    background: #2B58C4 url('images/icons/camera.png') no-repeat 5px 5px;
    padding-left: 34px;
}
.submit_button {
    background: #00206d;
}
.required {
    padding-left: 5px;
    color: red;
}

/* Modal */
.modal_informations {
    color: #00206d;
}

/* Responsive */
@media only screen and (max-width: 1200px)  {
    
    /* Home page */
    #home .logo, #home .bloc_bouton, #home .texte_3 {
        left: 5%;
    }  
    #home .infos_button {
        right: 5%;
    }
    #home .bloc_texte {
        top: 25%;
        right: 10%;
    }
    #home .bloc_bouton {
        top: 30%;
    }
    #home .bouton_home {
        width: 351px;
        height: 147px;
    }
    #home .texte_1 {
        font-size: 24px;
    }   

    #home .texte_2 {
        font-size: 32px;
    }
    #home .texte_3 {
        font-size: 16px;
    }
    
    /* Template */
    #header {
        padding-left: 20px;
        padding-right: 20px;
    } 
    #header .mention {
        right : 20px;
    }
}

@media only screen and (max-width: 1000px)  {
    #home .bloc_texte {
        top: 20%;
    }
}

@media only screen and (max-width: 900px)  {
    #home .bloc_texte {
        top: 15%;
    }
}

@media only screen and (max-width: 800px)  {
    #home {
        background : url('images/bg_small.gif') no-repeat top left;
    }
    #home .logo img, #header .logo img {
        height: 50px; 
    }  
    #home .bloc_texte {
        top: 15%;
        right: 0;
        width: 100%;
    }
    #home .bloc_bouton {
        top: 55%;
        left: 0;
        width: 100%;
        text-align: center;
    }
    #home .bouton_home {
        width: 251px;
        height: 105px;
    }
    #home .texte_1 {
        margin-bottom: 30px;
        font-size: 22px;
    }   
    #home .texte_2 {
        font-size: 30px;
        font-weight: normal;
    }
    #home .texte_3 {
        font-size: 16px;
    }
    #home .texte_1 .texte-bold, #home .texte_2 .texte-bold {
        font-weight: bold;
    }
    #header .mention {
        font-size: 18px;
    }
    .form-check-label {
        color: #000;
    }
    .form-section-title {
        font-size: 20px;
    }
}

@media only screen and (max-width: 600px)  {
    #home .texte_1 {
        font-size: 20px;
    }   

    #home .texte_2 {
        font-size: 28px;
        
    }
    #home .texte_3 {
        font-size: 14px;
    }
}

@media only screen and (max-height: 700px)  {
    #home .texte_1 {
        font-size: 16px;
    }   

    #home .texte_2 {
        font-size: 20px;
        
    }
    #home .texte_3 {
        font-size: 12px;
    }
    #home .bouton_home {
        width: 201px;
        height: 84px;
    }
}

@media only screen and (max-height: 550px)  {
    #home .bouton_home {
        width: 151px;
        height: 63px;
    }
}